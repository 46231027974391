import React from 'react';
import { Layout } from '@douyinfe/semi-ui';
import { Button } from '@douyinfe/semi-ui';
import { Divider } from '@douyinfe/semi-ui';
import { Tag, Space } from '@douyinfe/semi-ui';
function App() {
  const { Header, Content } = Layout;

  const commonStyle = {
    //头行的测试样式
    //         height: 64,
    //         lineHeight: '64px',
    // background: 'var(--semi-color-fill-0)'
  };

  const handleClick = (url, isPageScroll = false, isNewTab = false) => {
    if (isPageScroll) {
      const element = document.getElementById(url);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (isNewTab) {
      const isFullUrl = url.startsWith('http://') || url.startsWith('https://');
      if (!isFullUrl) {
        console.error('URL格式不正确，无法在新标签页中打开。');
      } else {
        window.open(url, '_blank');
      }
    } else {
      window.parent.postMessage({type: 'navigate', url: url}, '*'); // 记得替换'*'为精确来源
  }
  };

  // 定义内联样式对象
  const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '40px', // 控制按钮之间的间距
    marginTop: '40px'
  };

  return (
    <Layout className="components-layout-demo">
      <Header style={commonStyle}>
        <div id='1' style={{
          width: '800px',
          // height: '300px',
          margin: 'auto',
          textAlign: 'left' // 确保所有子元素向左对齐
        }}>
          <div style={{ fontSize: '40px', color: '#1e88e5', marginTop: '15px' }}>像用电一样用API</div>
          <div style={{
            fontSize: '16px',
            height: '40px',
            lineHeight: '40px',
            backgroundColor: '#60a5fa',
            color: 'white',
            display: 'inline-block',
            marginTop: '20px',
            padding: '0 4px'
          }}>注册、充值、填Key即用</div>
          <div style={{ marginTop: '40px' }}>
            <Space>
              <Tag size="large" shape='circle' color='violet' style={{ fontSize: '16px', height: '35px' }}> 模型价格不定时更改，随市场变动，请于模型价格界面查看调用详情 </Tag>
            </Space></div>
          <div style={centerStyle} >
            <Button onClick={() => handleClick('token')}>获取API令牌</Button>
            <Button type="secondary" onClick={() => handleClick('contact', true)}>联系客服</Button>
            {
              //教程这里应该根据不同的父界面（积分站，美元站）改跳转到的位置的，没写完
            }
            <Button type="tertiary" onClick={() => handleClick('https://www.yuque.com/u33403891/yz7trv/oyk9gn4x3u3enbn4?singleDoc# 《语渊GPT API 积分计费站点使用教程》', false, true)}>查看教程</Button>
            <Button type="danger" onClick={() => handleClick('pricing')}>查看模型价格</Button>
          </div>
          <Divider dashed='true' style={{ marginTop: '40px' }}></Divider>
        </div>
      </Header>
      <Content style={{ width: '800px', margin: 'auto', marginTop: '20px' }}>
        <div style={{ color: '#60a5fa', fontSize: '28px' }}>我们的优势</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}>🌍 #1 Brand API 品牌：使用官逆混合渠道，优先使用官转，官转消耗完后使用逆向渠道。已触达美国、日本、韩国、英国、新加坡、香港、菲律宾、俄罗斯等8个地区共计5万+客户，网站已稳定运行1年+，承诺永久运营！</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}>📝 完全兼容OpenAI接口协议，支持无缝对接各种支持OpenAI接口的应用！</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}>⭐ 按量计费，无需承担额度过期或者封号风险，MySQL8.2超高并发不限速，超强自研能力，基于渠道 RPM和TPM的智能负载均衡算法，响应快速，大幅降低失败率重试率，每日可承接100万次请求以上！</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}>⚡ 我们多机部署了7台离OpenAI最近的美国三网CN2线路服务器，出现波动自动切换服务器进行负载均衡，确保全球用户都能以最快速度获得响应！</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}> 已开通7*24小时自助充值，保障您的服务不会中断</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}>🎈 100%同步官方计费倍率和规则，无猫腻无套路不添加广告，性价比最高的稳定API源头，旗下已有70多位中转代理！</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}>🎨 Midjourney官方接口（默认V6）：支持最新版Midjourney Proxy Plus所有参数！支持二次转发代理！已集成图片国内反代、Discord国内反代、中文翻译接口、违禁词自动检测，使用多个120刀官方账号快速Turbo模式超高并发。</div>

        <Divider dashed='true' style={{ marginTop: '40px' }}></Divider>
      </Content>
      <Content style={{ margin: 'auto', marginTop: '30px', width: '800px' }}>
        <div style={{ color: '#60a5fa', fontSize: '28px' }}>可用模型</div>
        <div style={{ marginTop: '20px' }}><span style={{ color: '#60a5fa' }}>文字模型</span>——
          <Space>
            <Tag size="large" color='light-blue'>ChatGPT</Tag>
            <Tag size="large" color='light-blue'>Claude</Tag>
            <Tag size="large" color='light-blue'>通义千问</Tag>
            <Tag size="large" color='light-blue'>清华智谱</Tag>
            <Tag size="large" color='light-blue'>讯飞星火</Tag>
          </Space>
          <span style={{ color: 'grey', fontSize: '16px' }}>  等国内外知名大模型</span>
        </div>

        <div style={{ marginTop: '10px' }}><span style={{ color: '#60a5fa' }}>绘图模型</span>——
          <Space>
            <Tag size="large" color='light-blue'>DALL-E</Tag>
            <Tag size="large" color='light-blue'>Midjourney</Tag>
            <Tag size="large" color='light-blue'>Stable-Difussion</Tag>
          </Space>
          <span style={{ color: 'grey', fontSize: '16px' }}>等</span>
        </div>
        <div style={{ marginTop: '10px' }}><span style={{ color: '#60a5fa' }}>音乐模型</span>——
          <Space>
            <Tag size="large" color='light-blue'>Suno</Tag>
          </Space>
        </div>

        <Divider dashed='true' style={{ marginTop: '40px' }}></Divider>
      </Content>
      <Content style={{ margin: 'auto', marginTop: '30px', width: '800px' }}>
        <div style={{ color: '#60a5fa', fontSize: '28px', marginTop: '15px' }} >计费说明</div>
        <div style={{ color: 'grey', fontSize: '16px', marginTop: '15px' }}>无需昂贵的月费，语渊API <span>按实际使用的Token数量/次数收费</span>，模型价格约为官方费率的2折。</div>
        {/* <div style={{ color: 'red', fontSize: '16px', marginTop: '15px' }}>充值1美金花费2人民币</div> */}
        <div style={{ color: 'red', fontSize: '16px', marginTop: '15px' }}>充值25w积分花费20人民币</div>
        <div style={{ color: 'grey', fontSize: '16px', marginTop: '15px' }}>虽然Token和字符数并非一一对应，但你可以查看日志来查看具体明细</div>
        <div style={{ color: 'grey', fontSize: '13px', marginTop: '7px' }}>✲ 日志涵盖使用模型、tokens数、模型倍率</div>
        <Button theme='solid' type='secondary' style={{ marginTop: '15px' }} onClick={() => handleClick('log')}>查看日志</Button>
        <Divider dashed='true' style={{ marginTop: '40px' }}></Divider>
      </Content>
      <Content style={{ margin: 'auto', marginTop: '30px', width: '800px' }}>
        <div style={{ color: '#60a5fa', fontSize: '28px' }}>成为VIP</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}>为了更好的服务会员，<span style={{ color: '#60a5fa' }}>VIP</span>用户享有全模型折扣计费。</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}><span style={{ color: '#60a5fa' }}>VIP准入条件</span>：—— 查看【余额充值】页</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}><span style={{ color: '#60a5fa' }}>VIP享受权益</span>：—— 查看【余额充值】页</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}><span style={{ color: 'red' }}>企业专属接入</span>：—— 日消费3000以上，请咨询客服专属折扣</div>
        <Divider dashed='true' style={{ marginTop: '40px' }}></Divider>
      </Content>
      <Content style={{ margin: 'auto', marginTop: '30px', width: '800px' }} id='contact'>
        <div style={{ color: '#60a5fa', fontSize: '28px' }}>联系我们</div>
        <div style={{ color: 'grey', fontSize: '18px', marginTop: '15px' }}>
          <span style={{ color: '#60a5fa' }}>服务范围</span>—— 充值问题，使用问题，指定模型高并发免费定制，企业大额采购优惠等，我们会尽力服务好每一位客户，有任何问题不要害羞都可以直接问我
        </div>
        <div style={{ marginTop: '15px' }}>
          <img src="/contact.png" alt='contact' />
        </div>
        <div style={{ height: '400px' }}> </div>
      </Content>
    </Layout>
  );
};

export default App;